import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { IoSync } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { convToLocalDate } from "../../api/functions";
import { sendMessageRequest } from "../../api/messageRequests";
import { basicDialogInfoRequest } from "../../api/userRequests";
import ScreenTitle from "../../components/ScreenTitle";
import {
  LinkButton,
  LoadingButton,
  SubmitButton,
  TextLinkButton,
} from "../../components/buttons";
import { TextAreaField } from "../../components/inputs";
import {
  ErrorMessage,
  Loader,
  ProductName,
  RoundStatusTag,
} from "../../components/smallComponents";
import { messageDetailsRequest } from "../../redux/actions/userActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function DialogDetails() {
  useDocumentTitle("Message Details");
  const { id } = useParams();
  const messagesEndRef = useRef(null);

  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, details, success, error } = useSelector(
    (state) => state.messageDetails
  );

  const [newMessage, setNewMessage] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [refreshCount, setRefreshCount] = useState(0);

  const [dialog, setDialog] = useState("");
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    if (id) {
      dispatch(messageDetailsRequest(id));
    }
  }, [dispatch, reqStatus.success, refreshCount]);

  useEffect(() => {
    const getBasicDialogInfo = async () => {
      try {
        // setSaveStatus({ ...saveStatus, loading: true });
        const responseData = await basicDialogInfoRequest(id, config);
        if (responseData.data.success) {
          setDialog(responseData.data.data);
          //setSaveStatus({ ...saveStatus, loading: false, success: true });
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        // setSaveStatus({ ...saveStatus, loading: false, error: error });
      }
    };
    getBasicDialogInfo();
  }, []);

  // Scroll to bottom whenever messages update
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [details]); // Depend on messages

  const sendMessageAction = (e) => {
    e.preventDefault();

    if (!newMessage) {
      setFormErrors({ message: "Message is required." });
      return;
    }
    setFormErrors({ message: "" });

    let submitedValues = {
      recipientId: dialog.senderId === 0 ? dialog.recipientId : dialog.senderId,
      productId: dialog.productId,
      refType: dialog.refType,
      message: newMessage,
    };
    sendMessage(submitedValues);
    setNewMessage("");
  };

  const sendMessage = async (values) => {
    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await sendMessageRequest(values, config);

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const SenderMessage = ({ message }) => {
    return (
      <div className="w-full mt-2 flex items-end flex-col pl-4">
        <div className="flex p-1 items-end">
          <p className="text-darkGray text-sm">
            {Number(dialog.userId) === Number(dialog.senderId)
              ? dialog.senderName
              : dialog.recipientName}
          </p>
          <AiOutlineUser className={`w-5 h-5 ml-2 text-darkGray`} />
        </div>

        <div className="pr-8 flex flex-col items-start">
          <p className="text-white text-sm border border-solid border-darkGray bg-darkGray p-4 rounded-tl-2xl rounded-br-2xl rounded-bl-2xl">
            {message.message}
          </p>
        </div>
        <p className="pr-8 text-xs text-gray mt-1">
          {convToLocalDate(message.atime)}
        </p>
      </div>
    );
  };

  const RecipientMessage = ({ message }) => {
    return (
      <div className="w-full mt-2 pr-4">
        <div className="flex p-1 ">
          <AiOutlineUser className={`w-5 h-5 mr-2 text-darkGray`} />
          <p className="text-brandYellow text-sm">
            {Number(dialog.userId) === Number(dialog.senderId)
              ? dialog.recipientName
              : dialog.senderName}
          </p>
        </div>

        <div className="pl-8 flex flex-col items-start">
          <p className="text-sm border border-solid border-brandYellow p-4 rounded-tr-2xl rounded-br-2xl rounded-bl-2xl">
            {message.message}
          </p>
        </div>
        <p className="pl-8 text-xs text-gray mt-1">
          {convToLocalDate(message.atime)}
        </p>
      </div>
    );
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/messages">
            Messages
          </NavLink>
          <NavLink underline="hover" color="inherit">
            Details
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Message Details" />

        {loading ? (
          <Loader mess="Requesting details..." />
        ) : success ? (
          dialog ? (
            <div className="w-full border border-solid border-borderGray mt-8">
              <div
                className="relative w-full overflow-y-hidden"
                style={{ height: "calc(100vh - 160px)" }}
              >
                <div className="flex p-4 rounded bg-lightGray justify-between ">
                  <div className="flex items-center">
                    <AiOutlineUser className={`w-5 h-5 mr-2 text-darkGray`} />
                    <p className="text-gray text-sm">
                      {Number(dialog.userId) === Number(dialog.senderId)
                        ? dialog.recipientName
                        : dialog.senderName}
                    </p>
                  </div>
                  <button
                    className="flex items-center hover:text-brandYellow"
                    onClick={() => setRefreshCount(refreshCount + 1)}
                  >
                    <IoSync className={`w-5 h-5 mr-2`} />
                    Refresh
                  </button>
                </div>
                <div className="w-full border-b border-solid border-borderGray p-4 flex justify-between">
                  <ProductName
                    image={dialog.imageUris.length ? dialog.imageUris[0] : ""}
                    brandName={dialog.brandName}
                    modelName={dialog.modelName}
                    referenceNumber={dialog.referenceNumber}
                    price={dialog.price ? dialog.price : null}
                  />
                  <div className="flex flex-col justify-between items-end">
                    <RoundStatusTag
                      name={
                        dialog.refType === "CONSIGNMENT"
                          ? "Consignment"
                          :  dialog.refType === "SERVICE" 
                          ? "Service"
                          : "Product"
                      }
                      color={dialog.refType === "CONSIGNMENT" ? "green" : dialog.refType === "SERVICE" ? "yellow" : "blue"}
                    />
                    {dialog.refType === "CONSIGNMENT" && (
                      <div className="mt-4">
                      <TextLinkButton
                        name={"View Quote Details"}
                        path={`/consignment/details/${dialog.productId}`}
                      />
                       </div>
                    )}
                    {dialog.refType === "SERVICE" && (
                      <div className="mt-4">
                      <TextLinkButton
                        name={"View Quote Details"}
                        path={`/service/order/details/${dialog.productId}`}
                      />
                       </div>
                    )}
                  </div>
                </div>
                <div
                  className="overflow-y-auto flex-grow"
                  style={{ height: "calc(100vh - 520px)" }}
                >
                  {success &&
                    details.messages.length &&
                    details.messages.map((item, index) => (
                      <div
                        key={index}
                        ref={
                          index === details.length - 1 ? messagesEndRef : null
                        }
                      >
                        {Number(item.userId) === Number(item.senderId) ? (
                          <SenderMessage message={item} />
                        ) : (
                          <RecipientMessage message={item} />
                        )}
                      </div>
                    ))}
                </div>
                <div className="absolute bottom-0 w-full border-t border-solid border-borderGray bg-lightGray px-4 pb-4 ">
                  <form className="w-full" onSubmit={sendMessageAction}>
                    <TextAreaField
                      type="text"
                      label=""
                      name="message"
                      value={newMessage}
                      rows={2}
                      placeholder="Your reply..."
                      onChange={(message) => setNewMessage(message)}
                      error={formErrors.message}
                    />
                    <div className="w-fit mt-4">
                      {reqStatus.error && (
                        <ErrorMessage mess={reqStatus.error} />
                      )}
                      {reqStatus.loading ? (
                        <LoadingButton name="Sending Message" />
                      ) : (
                        <SubmitButton name={"Send Message"} />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <ErrorMessage mess={"Invalid request"} />
          )
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
